import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import fr_flag from "../../assets/images/france.png"
import uk_flag from "../../assets/images/uk.png"

const Navbar = () => {
    const [collapsed , setCollapsed] = useRecoilState(collapsedState);

    // const toggleNavbar = () => {
    //     setCollapsed(!collapsed);
    // }

    // React.useEffect(() => {
    //     let elementId = document.getElementById("navbar");
    //     document.addEventListener("scroll", () => {
    //         if (window.scrollY > 170) {
    //             elementId.classList.add("is-sticky");
    //         } else {
    //             elementId.classList.remove("is-sticky");
    //         }
    //     });
    //     window.scrollTo(0, 0);
    // })

    // const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    // const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';


    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container">
                        <nav style={{position:'absolute',right:40,top:25,}} className="navbar navbar-expand-lg navbar-light">
                            <Link 
                                to="/"
                                style={{marginRight:8,backgroundColor:'white',border:'1px solid #deded8',borderRadius:'8px', padding:'4px 8px',boxShadow:'1px 2px 6px #DEDED8',}}
                            >
                               <img src={fr_flag} style={{marginTop:'-5px'}} width="19px" alt="drapeau france" /> Français
                            </Link>
                            <Link 
                                to="/en"
                                style={{marginLeft:8,backgroundColor:'white',border:'1px solid #deded8',borderRadius:'8px', padding:'4px 8px',boxShadow:'1px 2px 6px #DEDED8',}}
                            >
                               <img src={uk_flag} style={{marginTop:'-5px',marginRight:'5px'}} width="19px" alt="drapeau allemagne" /> English
                            </Link>
                        </nav>
                    </div>
                </div>
                <div className="tarn-nav">
                    <div className="container ptb-10">
                        <nav style={{justifyContent:'center'}} className="navbar navbar-expand-lg navbar-light">
                            <Link 
                                to="/"
                                onClick={() => setCollapsed(true)} 
                            >
                                <img src={logo} id="logo-one" className="" width="318px" alt="logo dammann" />
                            </Link>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
