import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

const Salaries = () => {
    return (
        <section className="solutions-area ptb-70 bg-wspk">
            <div className="container">
                <div className="row align-items-center pb-30">
                    <div className="col-lg-12 col-md-12 pt-70 pb-30 center">
                        <h2 className="subtitle">Sind Sie Mitarbeiter oder Mitarbeiterin ?</h2>
                    </div>

                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>Greifen Sie auf Ihr Konto zu, um eine Meldung einzureichen, die Verarbeitung zu verfolgen, mit dem Referenten anonym oder vertraulich zu kommunizieren.</p>
                                    <Link to="https://agir.wispeek.com" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        mein wispeek bereich <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box section-title pt-50">
                                    <p>Sie möchten kein Konto erstellen. Dieses Formular wird verschlüsselt und an den Referenten gesendet. Sie werden nach Ihrer Identität gefragt.</p>
                                    <Link to="https://gerer.wispeek.com/s/3quiPDPshkBw4dKrt" target="_blank" className="default-btn" style={{backgroundColor:'coral'}}>
                                        <i className="flaticon-right"></i> 
                                        Melden <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                <div className="faq-accordion pt-50 pb-70">
                    <Accordion allowZeroExpanded>
                            
                        <AccordionItem uuid="a">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q1. Warum ein Konto erstellen
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <p><span className='bold'>Die Erstellung des Kontos erfolgt anonym:</span> Ihre E-Mail-Adresse und Ihr Passwort werden nicht gespeichert und mitgeteilt. Der Firmencode wird Ihnen von Ihrer Organisation zur Verfügung gestellt.</p>
                            <p className='bold'>Erstellen Sie Ihr Konto Wispeek</p>
                            <p>Diese Schnittstelle ist persönlich, nur Sie können darauf zugreifen. Achten Sie darauf, sich Ihr Passwort zu merken und es niemandem mitzuteilen.</p>

                            <p>Ihr Konto ermöglicht Ihnen:
                                <ul>
                                    <li>Erstellen Sie Berichte und Feedback im vertraulichen oder anonymen Modus</li>
                                    <li>Chatten Sie über eine sichere Nachricht mit dem für Ihre Datei zuständigen Referenten und tauschen Sie alle Arten von Dokumenten aus</li>
                                    <li>Konsultieren Sie die Ihnen zur Verfügung stehenden Dokumente (Unternehmenscharta, Verfahren, Formulare usw.).</li>
                                    <li>Behalten Sie Ihr Passwort, da die Daten verschlüsselt sind, können wir es nicht erneuern.</li>
                                </ul>
                            </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                            
                        <AccordionItem uuid="b">
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Q2. Bekenntnis zu Schutz und Verantwortung?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Wenn Sie eine Meldung machen möchten, können Sie sich als hinweisgebende Person an die vorhandenen internen Meldekanäle wenden oder aber diese Wispeek-Plattform nutzen. Eine hinweisgebende Person ist eine natürliche Person, die im Zusammenhang mit ihrer beruflichen Tätigkeit oder im Vorfeld einer beruflichen Tätigkeit Informationen über Verstöße erlangt hat.
                                    <br />
                                    Hinweise betreffen insbesondere folgende Verstöße, über die Sie als hinweisgebende Person Informationen erhalten haben:
                                </p>
                                <ul>
                                    <li>Verbrechen oder Vergehen;</li>
                                    <li>schwerwiegende und offensichtliche Verstöße gegen von Deutschland ordnungsgemäß ratifizierte oder anerkannte internationale Vereinbarungen;</li>
                                    <li>auf der Grundlage solcher Vereinbarungen getroffene einseitige Maßnahme internationaler Organisationen;</li>
                                    <li>Verstöße gegen gesetzliche Bestimmungen und Vorschriften;</li>
                                    <li>Gefährdungen oder schwerwiegende Verletzungen des öffentlichen Interesses;</li>
                                    <li>Verstöße gegen die entsprechenden deutschen Gesetze; </li>
                                    <li>Fälle des Verdachts der Geldwäsche oder Terrorfinanzierung.</li>
                                </ul>
                                <p>
                                    Wispeek garantiert Ihnen, dass Ihre Identität und die Informationen, die Sie offen legen, vertraulich behandelt werden. Informationen, die auf Ihre Identität schließen lassen, dürfen nur nach Ihrer Zustimmung weitergegeben werden, es sei denn, die zuständige Behörde oder das Gericht der internen Meldestelle hat mitgeteilt, dass durch Information entsprechende Ermittlungen, Untersuchungen oder Gerichtsverfahren gefährdet würden. Wir möchten Sie dazu ermutigen, Ihre Identität anzugeben, um die Bearbeitung zu erleichtern. Wenn Sie jedoch anonym bleiben möchten, garantiert Wispeek Ihre Anonymität. Ihre Informationen werden verschlüsselt und können nur von Ihrem Ansprechpartner gelesen werden. Bei unrichtigen Informationen, die vorsätzlich oder grob fahrlässig gemeldet werden, wird Ihre Identität nicht geschützt und Meldungen mit unrichtigen Informationen können Sanktionen nach sich ziehen.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>         
                </div>
            </div>
        </section>
    )
}

export default Salaries;